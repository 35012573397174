<template>
  <div :class="{'prize-detail': gift.status >= 1}">
    <div :class="{'white-background': gift.status >= 1}">
      <div class="prize-banner ">
        <dl>
          <dt>{{gift.name}}</dt>
          <dd>
            <p>奖品类型：{{gift.prizeLevel | prizeLevel}} </p>
            <p>中奖数量：1</p>
          </dd>
        </dl>
        <div class="prize-state">
          <span class="prize-cassh" v-if="gift.status === 0"></span>
          <span class="prize-award" v-if="gift.status === 1"></span>
          <span class="prize-received" v-if="gift.status === 2"></span>
        </div>
      </div>
      <!--以上banner-->
      <div class="detail-title">
        奖品兑换信息
      </div>
      <!-----以下未兑奖-实体------>
      <div class="cassh" v-if="gift.status === 0">
        <div class="cassh-input" v-if="gift.prizeType === 1">
          <ul>
            <li>
              <span class="cassh-width-left">收货人姓名</span>
              <cube-input class="detail-input" v-model="receiveName" placeholder="请输入姓名"></cube-input>
            </li>
            <li>
              <span class="cassh-width-left">收货人手机号</span>
              <cube-input class="detail-input" v-model="phone" placeholder="请输入手机号"></cube-input>
            </li>
            <li @click="showCityPicker">
              <span class="cassh-width-left">收货地区</span>
              <div class="selected-district" :class="{'selected': selectedDistrict}">{{selectedDistrict ? selectedDistrict : '请选择收货地区'}}</div>
              <div>
                <img :class="[districtPickerVisible?'top-arrow':'bottom-arrow']" src="~images/dow-ceremony/bottom-arrow.png" alt="" class="icon-selected-district">
              </div>
            </li>
            <li>
              <span class="cassh-width-left">收货地址</span>
              <cube-input class="detail-input" v-model="address" placeholder="请输入详细地址"></cube-input>
            </li>
          </ul>
        </div>

        <div class="cassh-input" v-if="gift.prizeType === 2">
          <ul>
            <li>
              <span class="cassh-width-left">充值手机号</span>
              <cube-input class="detail-input " v-model="phone" placeholder="请输入充值手机号"></cube-input>
            </li>
          </ul>
        </div>
        <div class="cassh-btn" @click="exchangePrizes">
          立即兑换
        </div>
        <div class="empty-box"></div>
      </div>

      <div class="received-box" v-if="gift.status === 1">
        <div class="received" v-if="gift.prizeType === 1">
          <ul>
            <li>
              <span class="cassh-width-left">收货人姓名</span>
              <span class="cassh-width-right">{{gift.userName}}</span>
            </li>
            <li>
              <span class="cassh-width-left">收货人手机号</span>
              <span class="cassh-width-right">{{gift.phone}}</span>
            </li>
            <li>
              <span class="cassh-width-left" style="float:left;">收货地址</span>
              <i class="cassh-width-left detail-text-right">{{gift.address}}</i>
            </li>
          </ul>
        </div>
        <div class="received" v-if="gift.prizeType === 2">
          <ul>
            <li>
              <span class="cassh-width-left">充值手机号</span>
              <span class="cassh-width-right">{{gift.phone}}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-----以下已兑奖-积分------>
      <div class="received-box" v-if="gift.status === 2">
        <div class="received" v-if="gift.prizeType === 1">
          <ul>
            <li>
              <span>收货人姓名</span>
              {{gift.userName}}
            </li>
            <li>
              <span>收货人手机号</span>
              {{gift.phone}}
            </li>
            <li>
              <span class="cassh-width-left" style="float:left;">收货地址</span>
              <i class="cassh-width-right detail-text-right">{{gift.address}}</i>
            </li>
            <li>
              <span>快递单号</span>
              <input type="text" id="logisticNum" v-model="gift.logisticNum" readonly>
              <button class="copy" data-clipboard-target="#logisticNum" @click="copyLogisticNum">复制</button>
            </li>
          </ul>
        </div>
        <div class="received" v-if="gift.prizeType === 2">
          <ul>
            <li>
              <span class="cassh-width-left">充值手机号</span>
              <span class="cassh-width-right">{{gift.phone}}</span>
            </li>
            <li>
              <span class="cassh-width-left">充值流水号</span>
              <span class="cassh-width-right">{{gift.flowNum}}</span>
            </li>
          </ul>
        </div>
        <div class="received" v-if="gift.prizeType === 3">
          <ul>
            <li>
              <span class="cassh-width-left">中奖人姓名</span>
              <span class="cassh-width-right">{{gift.contactName}}</span>
            </li>
            <li>
              <span class="cassh-width-left">中奖人手机号</span>
              <span class="cassh-width-right">{{gift.userPhone}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard'
import { regionData } from 'element-china-area-data'
import { giftDetail, cashGift, cashPhoneFee } from 'api/draw'
export default {
  data () {
    return {
      prizeId: '',
      gift: {
      },
      receiveName: '',
      selectedDistrict: '',
      address: '',
      phone: '',
      districtPickerVisible: false
    }
  },
  mounted () {
    let prize = this.$route.params
    this.prizeId = prize.id
    this.cityPicker = this.$createCascadePicker({
      title: '选择城市区域',
      data: regionData,
      alias: {
        text: 'label'
      },
      onSelect: (selectedVal, selectedIndex, selectedText) => {
        this.districtPickerVisible = false
        this.selectedDistrict = selectedText.join('/')
      },
      onCancel: () => {
        this.districtPickerVisible = false
      }
    })
    this.getGiftDetail()
  },
  methods: {
    getGiftDetail () {
      let params = this.prizeId
      giftDetail(params).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.gift = res.data.data || {}
      })
    },
    exchangePrizes () {
      if (this.gift.prizeType === 1) {
        if (!this.receiveName.trim()) {
          this.$createToast({
            txt: '收货人姓名不能为空',
            type: 'error',
            time: 2000
          }).show()
          return
        }

        if (!this.phone.trim()) {
          this.$createToast({
            txt: '收货人手机号不能为空',
            type: 'error',
            time: 2000
          }).show()
          return
        }

        if (!/^1\d{10}$/.test(this.phone.trim())) {
          const toast = this.$createToast({
            txt: '请正确输入11位数字的手机号',
            type: 'warn',
            time: 2000
          })
          toast.show()
          return
        }

        if (!this.selectedDistrict || !this.address.trim()) {
          this.$createToast({
            txt: '收货地址不能空',
            type: 'error',
            time: 2000
          }).show()
          return
        }
        const dialog = this.$createDialog({
          showClose: true
        }, (createElement) => {
          let self = this
          return [
            createElement('div',
              {
                'class': {
                  'my-title': true
                },
                slot: 'title'
              },
              [
                createElement('div', {
                  'class': {
                    'my-title-rule': true
                  }
                }, '奖品兑换信息'),
                createElement('div', {
                  'class': {
                    'my-title-span': true
                  }
                }, '请填写正确的兑奖信息,确认后将不可更改')
              ]),
            createElement('div',
              {
                'class': {
                  'my-prize-content-dialog': true
                },
                slot: 'content'
              },
              [
                createElement('table', {
                  'class': {
                    'my-content-prize-table': true
                  },
                  props: ['receiveName', 'phone', 'selectedDistrict', 'address']
                },
                  [
                    createElement('tr', [
                      createElement('td', '收货人姓名'),
                      createElement('td', this.receiveName)
                    ]),
                    createElement('tr', [
                      createElement('td', '收货人手机号'),
                      createElement('td', this.phone)
                    ]),
                    createElement('tr', [
                      createElement('td', '收货地址'),
                      createElement('td', this.selectedDistrict + this.address)
                    ])
                  ]),
                createElement('div', {
                  'class': {
                    'my-prize-button': true
                  }
                },
                  [
                    createElement('button', {
                      on: {
                        click: function () {
                          dialog.close()
                        }
                      }
                    }, '取消'),
                    createElement('button', {
                      style: {
                        color: '#FC4C5A'
                      },
                      on: {
                        click: function (e) {
                          self.giftOnCash()
                        }
                      }
                    }, '确认')
                  ])
              ])
          ]
        }).show()
      } else if (this.gift.prizeType === 2) {
        if (!this.phone.trim()) {
          this.$createToast({
            txt: '充值手机号不能为空',
            type: 'error',
            time: 2000
          }).show()
          return
        }

        if (!/^1\d{10}$/.test(this.phone.trim())) {
          const toast = this.$createToast({
            txt: '请正确输入11位数字的手机号',
            type: 'warn',
            time: 2000
          })
          toast.show()
          return
        }
        const dialog = this.$createDialog({
          showClose: true
        }, (createElement) => {
          let self = this
          return [
            createElement('div',
              {
                'class': {
                  'my-title': true
                },
                slot: 'title'
              },
              [
                createElement('div', {
                  'class': {
                    'my-title-rule': true
                  }
                }, '奖品兑换信息'),
                createElement('div', {
                  'class': {
                    'my-title-span': true
                  }
                }, '请填写正确的兑奖信息,确认后将不可更改')
              ]),
            createElement('div',
              {
                'class': {
                  'my-prize-content-dialog': true
                },
                slot: 'content'
              },
              [
                createElement('table', {
                  'class': {
                    'my-content-prize-table': true
                  },
                  props: ['phone']
                },
                  [
                    createElement('tr', [
                      createElement('td', '充值手机号'),
                      createElement('td', this.phone)
                    ])
                  ]),
                createElement('div', {
                  'class': {
                    'my-prize-button': true
                  }
                },
                  [
                    createElement('button', {
                      on: {
                        click: function () {
                          dialog.close()
                        }
                      }
                    }, '取消'),
                    createElement('button', {
                      style: {
                        color: '#FC4C5A'
                      },
                      on: {
                        click: function (e) {
                          self.phoneFeeCash()
                        }
                      }
                    }, '确认')
                  ])
              ])
          ]
        }).show()
      }
    },
    giftOnCash () {
      let district = this.selectedDistrict.split('/')
      let params = {
        address: district[0] + district[1] + district[2] + this.address,
        id: this.prizeId,
        phone: this.phone,
        name: this.receiveName
      }
      cashGift(JSON.stringify(params)).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.$router.push({ path: '/dc/prize' })
      })
    },
    phoneFeeCash () {
      let params = {
        id: this.prizeId,
        phone: this.phone
      }
      cashPhoneFee(JSON.stringify(params)).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.$router.push({ path: '/dc/prize' })
      })
    },
    showCityPicker () {
      this.districtPickerVisible = true
      this.cityPicker.show()
    },
    copyLogisticNum () {
      let clipboard = new Clipboard('.copy')
      clipboard.on('success', e => {
        this.$createToast({
          txt: '复制成功',
          type: 'correct',
          time: 2000
        }).show()
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        clipboard.destroy()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";

.white-background {
  background: #fff;
}

.prize-detail {
  height: 100vh;
  background: #f0f0f0;
}
.detail-text-right {
  width: 6.1rem !important;
  float: left;
  height: auto;
  padding-left: px2rem(8px);
  font-style: normal;
}
.prize-banner {
  width: px2rem(690px);
  min-height: px2rem(180px);
  display: inline-block;
  background: #f57d87;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: px2rem(30px);
  color: #fff;
  position: relative;
  & dl {
    height: px2rem(80px);
    display: inline-block;
    margin: px2rem(50px) 0;
    vertical-align: middle;
    dt {
      font-size: px2rem(46px);
      font-weight: bold;
      float: left;
      border-right: 1px solid #fff;
      border-color: rgba(255, 255, 255, 0.2);
      padding: 0 px2rem(40px);
      box-sizing: border-box;
      height: px2rem(80px);
      display: -webkit-box;
      -webkit-box-pack: center;
      -webkit-box-align: center;
      vertical-align: middle;
      width: px2rem(301px);
    }
    dd {
      font-size: px2rem(28px);
      float: right;
      padding: 0 px2rem(40px);
      line-height: px2rem(40px);
    }
  }
  & .prize-state {
    position: absolute;
    right: 0;
    bottom: px2rem(0px);
    height: px2rem(114px);
    & .prize-cassh {
      background: url(~images/dow-ceremony/cassh.png) no-repeat;
      background-size: cover;
      display: inline-block;
      width: px2rem(128px);
      height: px2rem(114px);
    }
    & .prize-award {
      background: url(~images/dow-ceremony/award-in.png) no-repeat;
      background-size: cover;
      display: inline-block;
      width: px2rem(128px);
      height: px2rem(114px);
    }
    & .prize-received {
      background: url(~images/dow-ceremony/received.png) no-repeat;
      background-size: cover;
      display: inline-block;
      width: px2rem(128px);
      height: px2rem(114px);
    }
  }
}
.detail-title {
  width: px2rem(690px);
  margin: px2rem(50px) px2rem(30px) px2rem(20px) px2rem(30px);
  font-size: px2rem(32px);
  font-weight: bold;
  color: $color-text;
}
/*未兑奖-实体*/
.cassh {
  margin: px2rem(20px) px2rem(30px) 0 px2rem(30px);
  // width:px2rem(750px);
  // box-sizing: border-box;
  // display: inline-block;
  .cassh-input {
    margin: px2rem(20px) 0 px2rem(60px) 0;
    border-radius: 6px;
    border: 1px solid $color-line;
    font-size: px2rem(28px);
    color: #363636;
    width: px2rem(690px);
    & li {
      font-size: px2rem(28px);
      height: px2rem(90px);
      line-height: px2rem(90px);
      border-bottom: 1px solid $color-line;
      display: inline-block;
      width: px2rem(690px);
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .cassh-width-left {
        float: left;
        width: px2rem(218px);
        color: $color-text;
        padding: 0 px2rem(30px) 0 px2rem(20px);
        box-sizing: border-box;
      }
      .cassh-width-right {
        float: right;
        width: px2rem(442px);
        color: $color-text;
        padding: 0 0 0 px2rem(20px);
        text-align: left;
      }
      & .detail-input {
        width: px2rem(464px);
        margin-top: px2rem(15px);
        display: inline-block;
        float: left;
        height: px2rem(70px);
        line-height: px2rem(70px);
      }
      .selected-district {
        display: inline-block;
        font-size: px2rem(28px);
        z-index: 1000;
        color: #ccc;
      }

      .selected {
        color: #363636 !important;
      }
    }
    & li:last-child {
      border-bottom: none;
    }
  }
}
.cassh-btn {
  margin: 0 auto;
  text-align: center;
  width: px2rem(450px);
  height: px2rem(90px);
  line-height: px2rem(90px);
  background: linear-gradient(
    132.9deg,
    rgba(255, 152, 91, 1),
    rgba(255, 63, 79, 1)
  );
  border-radius: 45px;
  font-size: px2rem(32px);
  color: #fff;
}
/*已兑奖-实体*/
.received-box {
  background: #f0f0f0;
  & .received {
    background: #fff;
    padding: 0 px2rem(30px) px2rem(30px) px2rem(30px);
    & li {
      font-size: px2rem(28px);
      line-height: px2rem(50px);
      width: 100%;
      display: block;
      overflow: hidden;
      & span {
        width: px2rem(168px);
        height: px2rem(50px);
        padding-right: px2rem(30px);
        display: inline-block;
      }
    }
  }
}
.icon-selected-district {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.bottom-arrow {
  display: inline-block;
  width: px2rem(24px);
  height: px2rem(12px);
  right: px2rem(20px);
}
.top-arrow {
  display: inline-block;
  width: px2rem(24px);
  height: px2rem(12px);
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  right: px2rem(20px);
}
#logisticNum {
  border: none;
  width: px2rem(265px);
  background: #fff;
}
.copy {
  width: px2rem(100px) !important;
  background: #fff;
  outline: none;
  border: none;
  color: #fc4c5a;
  margin: 0 0.3rem;
  display: inline-block;
  cursor: pointer;
}
.empty-box {
  width: 100%;
  min-height: px2rem(100px);
  margin-top: px2rem(10px);
}
</style>

